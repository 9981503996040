import {colors} from '../../../constants'

export default {
    baseStyle: {
        container: {
            position: 'relative'
        },
        bannerContent: {
            position: 'absolute',
            maxWidth: {base: '259px', xl: '633px'},
            color: 'white',
            left: {base: '21px', xl: '106px'},
            bottom: {base: '76px', xl: '140px'}
        },
        bannerHeading: {
            fontSize: {base: '32px', xl: '64px'},
            lineHeight: {base: '37px', xl: '75px'},
            marginBottom: {base: '20px', xl: '40px'},
            fontWeight: {base: '400', xl: '300'}
        },
        shopNow: {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: 'white',
            color: 'white',
            borderRadius: '20px',
            fontWeight: '300',
            fontFamily: 'Roboto',
            fontSize: 'md',
            width: 'fit-content',
            paddingRight: {base: '20px', lg: '32px'},
            paddingLeft: {base: '20px', lg: '32px'},
            paddingTop: "8px",
            paddingBottom: "8px",
            _hover: {
                bg: 'transparent'
            }
        }
    }
}
