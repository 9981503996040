import {colors} from '../../../constants/colors'

export default {
    baseStyle: {
        page: {
            fontSize: '16px',
            flexWrap: 'wrap',
            mx: 'auto',
            width: '100%',
            maxWidth: '1903px',
            minHeight: '100vh',
            justifyContent: 'center'
        },
        topBarSx: {
            '.left-scroll': {
                'overflow-x': 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
            },
            '.left-scroll::-webkit-scrollbar': {
                display: 'none'
            }
        },
        topBar: {
            width: {base: '100%', xl: '25%'},
            flexDirection: {base: 'row', xl: 'column'},
            borderBottom: {base: `1px solid ${colors.brightGray}`, xl: 'none'},
            paddingLeft: {base: '20px', xl: '30px'},
            overflowX: 'auto'
        },
        content: {
            maxWidth: '485px',
            padding: '16px',
            width: '100%'
        },
        leftBtn: {
            whiteSpace: 'nowrap',
            marginRight: '16px',
            width: {xl: 'fit-content'},
            marginBottom: {xl: '24px'}
        },
        tab: {
            p: '0 2px',
            color: '#667085',
            marginRight: '16px',
            _selected: {color: `${colors.blueCol}`}
        },
        tabLink: {
            fontSize: '14px',
            fontWeight: '300',
            display: 'inline-block',
            padding: '11px 0',
            lineHeight: '24px'
        },
        richContentSx: {
            h4: {
                fontSize: '24px',
                fontWeight: '300',
                lineHeight: '28.13px',
                marginBottom: '11px',
                fontFamily: `'Roboto', sans-serif`,
                marginTop: '54px'
            },
            h3: {
                fontSize: {base: '20px', xl: '16px'},
                marginBottom: '20px',
                fontWeight: '300',
                fontFamily: `'Roboto', sans-serif`
            },
            p: {
                marginBottom: '20px',
                fontSize: '16px',
                fontFamily: `'Roboto', sans-serif`,
                fontWeight: '300',
                lineHeight: '18.75px'
            },
            ul: {
                paddingLeft: '20px',
                li: {
                    p: {
                        marginBottom: '0'
                    }
                }
            }
        },
        richContent: {
            color: `${colors.blueCol}`,
            fontWeight: '300'
        },
        contentHeading: {
            fontFamily: `'Raleway', sans-serif`,
            fontSize: '48px',
            fontWeight: '300',
            lineHeight: '56.35px',
            marginTop: {base: '75px', xl: '115px'}
        }
    }
}
