export const headerDataFr = {
    headerCollection: {
        items: [
            {
                leftCollection: {
                    items: [
                        {
                            sys: {
                                id: '3Ar43vJ1kCyxBFdS4kK6dU'
                            },
                            __typename: 'List',
                            name: null,
                            heading: 'Tout voir',
                            itemsCollection: {
                                items: [
                                    {
                                        sys: {
                                            id: '1q4gYgYZOVrODuFPAP4bp3'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Maquillage',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Le Visage',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Base pour le visage',
                                                                url: '/category/FA_PRIMER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Fond de teint',
                                                                url: '/category/Foundation',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WhbbbODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Crème teintée',
                                                                url: '/category/TINT_MOIST',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVskmk1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Couleur correctrice',
                                                                url: '/category/CONCEALER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVmmns1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Poudre',
                                                                url: '/category/POWDER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsbbj1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Poudre et vaporisateur de fixation',
                                                                url: '/category/SETTING_SP',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DnnLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Fard à joues',
                                                                url: '/category/BLUSH',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVskn1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Enlumineur',
                                                                url: '/category/HIGHLIGHTR',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DnkLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Poudre bronzante',
                                                                url: '/category/BRONZER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzmmlkVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils & Accessoires',
                                                                url: '/category/FA_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVnsknk1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Applicateur pour le visage',
                                                                url: '/category/FA_APPLIC',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WvvhODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tout le visage',
                                                                url: '/category/MKP_TOTAL_FACE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscjkiNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Les Yeux',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAbhJbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Sourcils',
                                                                url: '/category/EYE_BROW',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFnhvhkvC5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Base pour les yeux',
                                                                url: '/category/EYE_PRIMER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1Wbnh1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Fard à paupières',
                                                                url: '/category/EYE_SHADOW',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1bhvhvkhDLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Crayon pour les yeux / Liner',
                                                                url: '/category/EYE_LINER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1mjWODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Rimmel',
                                                                url: '/category/MASCARA',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1mknkkWODVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Faux cils',
                                                                url: '/category/EYELASHES',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOnkklnlkDVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils & Accessoires',
                                                                url: '/category/EYE_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsnknk1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Applicateur',
                                                                url: '/category/EYE_APPLIC',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WObjjVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Autre',
                                                                url: '/category/MKP_TOTAL_EYE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavPml4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Les Lèvres',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2Zrj9cxdknnUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Base pour les lèvres',
                                                                url: '/category/LIP_PRIMER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7nnSfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Couleur de lèvres',
                                                                url: '/category/LIP_COLOR',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODm1Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Gloss',
                                                                url: '/category/LIP_GLOSS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVm2s1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Crayon à lèvres',
                                                                url: '/category/LIP_LINER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1m3DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils & Accessoires',
                                                                url: '/category/LIP_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVm4s1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Applicateur pour les lèvres',
                                                                url: '/category/LIP_APPLIC',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsm51DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Toutes les lèvres',
                                                                url: '/category/MKP_TOTAL_LIP',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4m6xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Les Ongles',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2m7Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soin des ongles',
                                                                url: '/category/NAIL_CARE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CA7Sm8fF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Base & Top Coat',
                                                                url: '/category/BASE_COATS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHm9zVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Couleur des ongles',
                                                                url: '/category/CLR_ENAMEL',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1Dm1oLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils & Accessoires pour les ongles',
                                                                url: '/category/NAIL_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allNails'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les ongles',
                                                                url: '/category/MKP_TOTAL_NAIL',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnavP4xm11lztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Outils de maquillage',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm12m2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Pinceaux & Applicateur',
                                                                url: '/category/OTH_FACE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CAm237SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Accessoires',
                                                                url: '/category/ALL_OTHER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODm123Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Applicateurs',
                                                                url: '/category/OTH_APPLIC',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOm16DVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils & Accessoires',
                                                                url: '/category/OTH_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'makeUpToolsFrench'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les outils de maquillage',
                                                                url: '/category/OTH_TOOLS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnam17vP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Coffrets de maquillage',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJm18bDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Coffrets de maquillage',
                                                                url: '/category/SET',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'setFrench'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les ensembles de maquillage',
                                                                url: '/category/SET',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllMakeup'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'Tout Maquillage',
                                                    url: '/category/MAKEUP',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: '3mKyh7v1eerm19JtsbBzl6J97'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Soins pour la peau',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '25gscNm20navP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Le Visage',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDm2m21Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Nettoyants',
                                                                url: '/category/FA_CLNSR',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5CAm227SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soins',
                                                                url: '/category/FA_SERUM',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1Dm23LHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Yeux',
                                                                url: '/category/EYE_TRTMNT',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1Dm234LHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Masque pour les yeux & le visage',
                                                                url: '/category/S_MASK',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHm234zVhbN7ImBlIp1'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hydratants',
                                                                url: '/category/FA_CREAM',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1rg1WODVs1DLHzVhbN7ImBlIp00'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tout le visage',
                                                                url: '/category/SKIN_TOTAL_FACE',

                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                // {
                                                //     sys: {
                                                //         id: '25gscNnam26vP4xlztprPLUhG'
                                                //     },
                                                //     __typename: 'List',
                                                //     name: null,
                                                //     heading:
                                                //         'Soins pour Hommes',
                                                //     itemsCollection: {
                                                //         items: [
                                                //             {
                                                //                 sys: {
                                                //                     id: '6TAJbDm2m27Zrj9cxdUSImfse'
                                                //                 },
                                                //                 __typename:
                                                //                     'Link',
                                                //                 text: 'Soins pour Hommes',
                                                //                 url: '/',
                                                //                 externalUrl:
                                                //                     null,
                                                //                 openLinkInNewTab: false,
                                                //                 iconName: null,
                                                //                 theme: null,
                                                //                 type: 'unstyled',
                                                //                 isExternal: false
                                                //             }
                                                //         ]
                                                //     }
                                                // },
                                                {
                                                    sys: {
                                                        id: '25gm28scNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Soins du corps',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDmm292Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Nettoyant pour le corps',
                                                                url: '/category/S_OTH_BODY',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFm30C5CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soin du corps',
                                                                url: '/category/BO_SERUM',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODm32Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Hydratant pour le corps',
                                                                url: '/category/BO_CRM_LTN',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODm31Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Savon pour les mains',
                                                                url: '/category/HAND_SOAP',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOm33DVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Déodorant & Antisudorifique',
                                                                url: '/category/S_DEO',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1nmWODVs1DLHzVhbN7ImBlIpfhd'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soins du corps',
                                                                url: '/category/SKIN_TOTAL_BODY',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNn1navP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Protection solaire',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAn2JbDm2Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Crème solaire pour le visage',
                                                                url: '/category/FA_IN_SUN',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5n3CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Crème solaire pour le corps',
                                                                url: '/category/BO_IN_SUN',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOn4DVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Après-soleil',
                                                                url: '/category/AFTER_SUN',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WOn6DVs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Autobronzant',
                                                                url: '/category/S_TANNER',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1DLHzVhbN7IggmBlIp21'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soins Solaires Spf Tous',
                                                                url: '/category/SKIN_TOTAL_SUN',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNnn8avP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Outils de soins de la peau',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDmn92Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils pour le visage',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5n10CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils pour le corps',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFC5bvnCA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Outils de soins de la peau',
                                                                url: '/category/SKIN_SETS___KITS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: '25gscNl1navP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Ensembles de soins de la peau',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDml22Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Ensembles de soins de la peau',
                                                                url: '/category/SETS_KITS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDmbnv2Zfrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les coffrets de soins',
                                                                url: '/category/SKIN_SETS___KITS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllSkincare'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'Tous les soins pour la peau',
                                                    url: '/category/SKINCARE',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: 'PKQeaNzSfa5TaGNlZvg35'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Parfums',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: '25gsl34cNnavP4xlztprPLUhG'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Parfums',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: '6TAJbDml782Zrj9cxdUSImfse'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eau de Toilette',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1AFCl905CA7SfF41rVexinsxM'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eau de Parfum',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVs1Dk1LHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Parfum',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODk2Vs1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Eau de Cologne',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVk3s1DLHzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Solide & Innovant',
                                                                url: '/',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: '1WODVsbbfj1DLHggzVhbN7ImBlIp'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les parfums',
                                                                url: '/category/FRAG_ANCILLARIES',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'homeAndLifestyleCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Maison & Style de vie',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleCandles'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Bougies',
                                                                url: '/category/CANDLES',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleDiffusers'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Diffuseurs pour la maison',
                                                                url: '/category/DIFFUSERS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleCarDiffuser'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Diffuseur pour voiture',
                                                                url: '/category/HOME_SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleRoomFresheners'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Désodorisants & Accessoires',
                                                                url: '/category/POTPOURRI',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'homeAndLifestyleRoomFresheners'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tout Maison et style de vie',
                                                                url: '/category/FRAG_HOME_SCENTS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'bathAndBodyCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Bain & Corps',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyCleanser'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Nettoyant pour le corps',
                                                                url: '/category/F_OTH_BODY',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyLotion'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lotion pour le corps',
                                                                url: '/category/BODY_OIL',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyBodyMist'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Brume pour le corps',
                                                                url: '/category/BODY_SPRAY',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyHairMist'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Brume pour les cheveux',
                                                                url: '/category/HAIR',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyForTheBath'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Pour le bain',
                                                                url: '/category/BUBLE_BATH',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'bathAndBodyDeodorant'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Déodorant',
                                                                url: '/category/F_DEO',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            // {
                                                            //     sys: {
                                                            //         id: 'bathAndBodyGrooming'
                                                            //     },
                                                            //     __typename:
                                                            //         'Link',
                                                            //     text: 'Soins personnels',
                                                            //     url: '/category/SHAVE',
                                                            //     externalUrl:
                                                            //         null,
                                                            //     openLinkInNewTab: false,
                                                            //     iconName: null,
                                                            //     theme: null,
                                                            //     type: 'unstyled',
                                                            //     isExternal: false
                                                            // },
                                                            {
                                                                sys: {
                                                                    id: 'allBathAndBody'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tout le bain et le corps',
                                                                url: '/category/FRAG_TOTAL_JUICES',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'fragranceSetsCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Coffrets de parfums',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'fragranceSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Coffrets de parfums',
                                                                url: '/category/OTH_SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'fragranceSetsAll'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les coffrets de parfums',
                                                                url: '/category/FRAG_GIFT_SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllFragrance'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'Tous les parfums',
                                                    url: '/category/FRAGRANCES',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: '2YHIActKx8AVKtY8mA4CcM'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Cheveux',
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: 'customerFacingCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Shampooing & Après-shampooing',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'customerFacingShampoo'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Shampooing',
                                                                url: '/category/DAILY_SHAM',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'customerFacingConditioner'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Après-shampooing',
                                                                url: '/category/DAILY_COND',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'hairCareAll'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les soins capillaires',
                                                                url: '/category/HAIR_CARE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'treatmentCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Traitement',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'treatmentHairMask'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Masque capillaire',
                                                                url: '/category/H_MASK',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'treatmentHairColor'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Coloration des cheveux',
                                                                url: '/category/COLOR',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'treatmentScalpCare'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soin du cuir chevelu',
                                                                url: '/category/OTH_CARE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'stylingCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Produits de coiffage',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'stylingHairStyling'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Fixatif capillaire',
                                                                url: '/category/OTH_STYL',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'stylingHairStylingSpray'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Spray coiffant',
                                                                url: '/category/SPRAY',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'stylingMousseFoam'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Mousse coiffante',
                                                                url: '/category/MOUSSE',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'stylingDryShampoo'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Shampooing sec',
                                                                url: '/category/DRY_SHAM',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'AllHairStyling'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Toutes les coiffures',
                                                                url: '/category/HAIR_STYLING',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'hairSetsCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Coffrets pour cheveux',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'hairSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Coffrets pour cheveux',
                                                                url: '/category/SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'hairSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les ensembles de maquillage',
                                                                url: '/category/MKP_TOTAL_SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'allHairSets'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Tous les ensembles de cheveux',
                                                                url: '/category/HAIR_SETS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'shopAllHair'
                                                    },
                                                    __typename: 'Link',
                                                    name: null,
                                                    text: 'Tout Parfums',
                                                    url: '/category/HAIR',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },

                                    {
                                        sys: {
                                            id: 'brands'
                                        },
                                        __typename: 'Link',
                                        name: null,
                                        text: 'Marques',
                                        url: '/pages/brands',
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: '1NaEeldvjCHUOVfJAIrnGo'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Bien-être (à venir)',
                                        isDisabled: true,
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: 'supplementsAndBeveragesCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Compléments alimentaires & Boissons',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'supplements'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Compléments alimentaires',
                                                                url: '/category/SUPP_TEAS',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'Vitamines'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Vitamins',
                                                                url: '/category/SUPP_VIT',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'Collagène'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Collagen',
                                                                url: '/category/SUPP_COLL',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'functionalMushrooms'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Champignons fonctionnels',
                                                                url: '/category/SUPP_MUSH',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'sexualWellnessCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading: 'Bien-être sexuel',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'vibratorsToys'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Vibromasseurs & Jouets',
                                                                url: '/category/SW_MEN_T',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            },
                                                            {
                                                                sys: {
                                                                    id: 'lubricant'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Lubrifiant',
                                                                url: '/category/SW_LUB',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    sys: {
                                                        id: 'oralCareCategory'
                                                    },
                                                    __typename: 'List',
                                                    name: null,
                                                    heading:
                                                        'Soin bucco-dentaire',
                                                    itemsCollection: {
                                                        items: [
                                                            {
                                                                sys: {
                                                                    id: 'oralCare'
                                                                },
                                                                __typename:
                                                                    'Link',
                                                                text: 'Soin bucco-dentaire',
                                                                url: '/category/TOOTHBRUSH',
                                                                externalUrl:
                                                                    null,
                                                                openLinkInNewTab: false,
                                                                iconName: null,
                                                                theme: null,
                                                                type: 'unstyled',
                                                                isExternal: false
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: '1bbNaEeldvjCHUOVfJAIrnGo'
                                        },
                                        __typename: 'List',
                                        name: null,
                                        heading: 'Et Tout le Reste',
                                        isDisabled: true,
                                        itemsCollection: {
                                            items: [
                                                {
                                                    sys: {
                                                        id: 'restOfItItems1'
                                                    },
                                                    __typename: 'Link',
                                                    text: 'Hydratation',
                                                    url: '/',
                                                    externalUrl:
                                                        'https://rennai.com',
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                },
                                                {
                                                    sys: {
                                                        id: 'restOfItItems2'
                                                    },
                                                    __typename: 'Link',
                                                    text: 'Nouveauté',
                                                    url: '/category/NOV_ACCESSORIES',
                                                    externalUrl: null,
                                                    openLinkInNewTab: false,
                                                    iconName: null,
                                                    theme: null,
                                                    type: 'unstyled',
                                                    isExternal: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        sys: {
                                            id: 'restOfItItems3'
                                        },
                                        __typename: 'Link',
                                        text: 'Nouveauté',
                                        isDisabled: true,
                                        url: '/',
                                        externalUrl: null,
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    },
                                    {
                                        sys: {
                                            id: 'restOfItItems4'
                                        },
                                        __typename: 'Link',
                                        text: 'Meilleurs vendeurs',
                                        isDisabled: true,
                                        url: '/',
                                        externalUrl: '/',
                                        openLinkInNewTab: false,
                                        iconName: null,
                                        theme: null,
                                        type: 'unstyled',
                                        isExternal: false
                                    }
                                ]
                            }
                        },
                        {
                            sys: {
                                id: '5DLddapvHfrI9EVOxVDH0U'
                            },
                            __typename: 'Link',
                            text: 'Sphère',
                            url: '/pages/blogs',
                            externalUrl: null,
                            openLinkInNewTab: false,
                            iconName: null,
                            theme: null,
                            type: 'tertiary',
                            isExternal: false
                        },
                        {
                            sys: {
                                id: 'aboutLinkHeader'
                            },
                            __typename: 'Link',
                            text: 'À propos',
                            url: '/pages/about',
                            externalUrl: null,
                            openLinkInNewTab: false,
                            iconName: null,
                            theme: null,
                            type: 'tertiary',
                            isExternal: false
                        }
                    ]
                }
            }
        ]
    }
}
