import {gql} from 'graphql-request'
import {MEDIA_FRAGMENT} from './fragments'

export const brandsQuery = gql`
    ${MEDIA_FRAGMENT}
    query Brands($locale: String!) {
        brandCollection(limit: 200, locale: $locale) {
            items {
                name
                headline
                code: uniqueName
                website
                logo {
                    ...MediaFragment
                }
                bannerImage {
                    ...MediaFragment
                }
                category
                placeOfOrigin
                description
                plpUrl
                shopInStoreOnly
            }
        }
    }
`
