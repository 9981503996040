import {gql} from 'graphql-request'

export const getCookieModalQuery = gql`
    query CookieModal($locale: String!) {
        modalCollection(
            limit: 1
            locale: $locale
            where: {name: "cookie"}
        ) {
            items {
                contentfulTitle
                mediaAlignment
                heading
                headingType
                body
            }
        }
    }
`
