import {
    Box,
    Image,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'

import {styleConfigChakraKeys} from '../../../constants'
import {productUrlBuilder} from '../../../utils/url'
import Link from '../../link'
import ProductPricing from '../../product-pricing'

export default function SearchProductTile(props) {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.searchProductTile)
    const {product, onLinkClick, ...rest} = props

    // TODO: Add title case for text
    return (
        <Box {...rest} {...styles.tile}>
            <Link
                url={productUrlBuilder({
                    id: product.productId
                })}
                onClick={onLinkClick}
                type="tertiary"
                _hover={{textDecoration: 'none'}}
            >
                <Box {...styles.imgContainer}>
                    <Image src={product?.image} />
                </Box>
                {product.brand && <Box {...styles.brand}>{product.brand}</Box>}
                <Box
                    {...styles.name}
                    dangerouslySetInnerHTML={{
                        __html: product.name
                    }}
                />
                <Box {...styles.price} sx={styles.priceSx}>
                    <ProductPricing product={product} />
                </Box>
            </Link>
        </Box>
    )
}

SearchProductTile.propTypes = {
    product: PropTypes.object,
    onLinkClick: PropTypes.func
}
