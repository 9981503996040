import {colors} from '../../../constants'

export default {
    baseStyle: {},
    variants: {
        event: {
            box: {
                flexDirection: 'column',
                border: '1px solid white',
                rounded: '4px',
                padding: '18px',
                flex: '0 0 auto',
                fontSize: 'sm',
                width: 'fit-content',
                paddingBottom: {base: '20px', lg: '44px'},
                cursor: 'pointer'
            },
            contentBox: {
                flexDirection: 'column',
                justifyContent: 'center'
            },
            link: {
                marginTop: {base: '23px', lg: '34px'},
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: `${colors.light}`,
                color: `${colors.light}`,
                borderRadius: '20px',
                fontWeight: '300',
                padding: '8px 12px',
                fontFamily: 'Roboto',
                fontSize: 'md',
                width: 'fit-content',
                paddingRight: {base: '45px', lg: '58px'},
                paddingLeft: {base: '45px', lg: '58px'},
                paddingTop: {base: '8px', lg: '6px'},
                paddingBottom: {base: '8px', lg: '6px'}
            },
            h1: {
                color: '#FFF'
            },
            h6: {
                color: '#FFF',
                fontWeight: 'normal',
                marginTop: {base: '20px', lg: '28px'},
                fontSize: 'xl',
                marginBottom: '13px',
                fontFamily: 'Roboto'
            },
            text: {
                marginBottom: '14px',
                color: '#FFF',
                fontSize: 'sm',
                width: '80%',
                fontFamily: 'Roboto'
            },
            img: {
                borderRadius: '2px'
            }
        },
        service: {
            box: {
                display: 'flex',
                flexDirection: {base: 'column', lg: 'row'},
                color: 'black',
                backgroundColor: colors.grayBgColor,
                gap: {base:'38px', xl: '80px'},
                px: {base: '16px', lg: '0'}
            },
            contentBox: {
                flexDirection: 'column',
                margin: {
                    base: 'auto 38px 44px 38px',
                    lg: '142px auto 48px 60px'
                },
                // width: {base: '100%', lg: '40%'},
                fontSize: '24px',
                fontFamily: `"Roboto" , sans-serif`,
                fontWeight: '300',
                lineHeight: '28.13px',
                color: 'black',
                gap: {base: '31px', lg: '43px'},
                alignItems: {base: 'center', lg: 'flex-start'},
                alignSelf: 'center',
                pb: {base: '16px', lg: '0'}
            },
            imageContainer: {
                width: {base: '100%', lg: '50%'},
                overflow: 'hidden'
            },

            h6: {
                fontSize: '24px',
                marginBottom: {base: '8px', lg: '16px'},
                marginTop: {base: '40px', lg: '0'},
                lineHeight: '28px',
                color: 'black',
                fontWeight: '300',
                fontFamily: `"Roboto" , sans-serif`
            },
            link: {
                backgroundColor: 'transparent',
                fontWeight: '300',
                fontFamily: `"Roboto" , sans-serif`,
                fontSize: 'md',
                textDecoration: 'underline',
                color: 'black',
                paddingTop: {base: '8px', lg: '6px'},
                paddingBottom: {base: '8px', lg: '6px'},
                display: {base: 'block', lg: 'inline-block'},
                minWidth: {base:'152px', lg: 'unset'},
                width: {base: '100%', lg: 'fit-content'},
                _hover: {
                    textDecoration: 'none',
                    backgroundColor: 'transparent'
                },
                _focus: {
                    backgroundColor: 'transparent'
                }
            },
            text: {
                color: 'black',
                fontWeight: '300',
                fontFamily: `"Roboto" , sans-serif`,
                fontSize: '24px',
                lineHeight: '28.13px'
            },
            textMargin: {
                marginBottom: {base: '32px', lg: '39px'}
            }
        },
        profile: {
            box: {
                display: 'flex',
                flexDirection: {base: 'column', lg: 'row'},
                color: 'black'
            },
            contentBox: {
                flexDirection: 'column',
                paddingLeft: {lg: '60px'},
                paddingTop: {lg: '48px'},
                paddingBottom: {lg: '48px'},
                width: {base: '100%', lg: '40%'},
                maxWidth: {lg: '373px'},
                fontSize: '16px',
                fontFamily: `"Roboto" , sans-serif`,
                fontWeight: '300',
                lineHeight: '19px',
                color: 'black',
                minHeight: '282px'
            },
            imageContainer: {
                borderRadius: {base: '4px', lg: '4px'},
                overflow: 'hidden',
                width: '100%',
                maxWidth: {base: '100%', md: '522px', lg: '490px'},
                maxHeight: {base: '300px', lg: '380px'}
            },

            h6: {
                fontSize: '24px',
                marginBottom: '16px',
                marginTop: {base: '20px', lg: '0'},
                lineHeight: '28px',
                color: 'black',
                fontWeight: '300',
                fontFamily: `"Roboto" , sans-serif`
            },
            link: {
                backgroundColor: 'transparent',
                border: '1px solid',
                borderRadius: '20px',
                fontWeight: '300',
                padding: '8px 12px',
                fontFamily: `"Roboto" , sans-serif`,
                fontSize: 'md',
                textAlign: {base: 'center', lg: 'start'},
                color: 'black',
                paddingTop: {base: '8px', lg: '6px'},
                paddingBottom: {base: '8px', lg: '6px'},
                display: {base: 'block', lg: 'inline-block'},
                marginTop: {base: '16px', lg: '30px'},
                minWidth: '152px',
                maxWidth: '400px',
                width: {base: '100%', lg: 'fit-content'},
                _hover: {
                    textDecoration: 'none'
                }
            },
            text: {
                color: 'black',
                fontWeight: '300',
                fontFamily: `"Roboto" , sans-serif`
            },
            textMargin: {
                marginBottom: {base: '16px', lg: '39px'}
            }
        }
    }
}
