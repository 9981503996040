import {contentfulQueryKeys} from '../../../constants'
import {getHomeHeroSectionQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useHomeHero = (sectionName) => {
    const {data, error, isLoading} = useContentfulQueryWithHook(
        [...contentfulQueryKeys.getHomeHeroSection, sectionName],
        getHomeHeroSectionQuery,
        {sectionName}
    )
    return {data, error, isLoading}
}
